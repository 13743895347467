<template>
<!-- 社会指导员 -->
    <div>
        <div class="goBack">
            <div @click="getBack('shaoxingone')">
                <img src="../../assets/images/return.png" alt="">
            </div>
            <span>社会体育指导员</span>
        </div>
        <div class="posit-left">
            <dataanalysis style="margin-bottom:0.21rem;"></dataanalysis>
            <sincetype></sincetype>
        </div>
        <div class="socical-top">
            <socialcenter></socialcenter>
        </div>
        <div class="posit-right">
            <sportsinstructor></sportsinstructor>
        </div>
    </div>
</template>

<script>
import {show} from '../../assets/js/animate'
export default {
    components: {
        dataanalysis:() => import('../charts/socialinstructor/dataanalysis.vue'),
        sincetype:() => import('../charts/socialinstructor/sincetype.vue'),
        socialcenter:() => import('../charts/socialinstructor/socialcenter.vue'),
        sportsinstructor:() => import('../charts/socialinstructor/sportsinstructor.vue'),
    },
    data () {
        return {
            
        }
    },
    methods: {
        getBack(showdata){
            let _this = this;
            show(showdata, _this);
			$('#amap').show();
        }
    }
}
</script>

<style scoped>
.socical-top{
    position: absolute;
    top: 1.36rem;
    left:34.6%;
}

</style>